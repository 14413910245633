<template>
  <Layout>
    <!-- start page title -->
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h4 class="font-size-18">{{ title }}</h4>
          <div class="page-title-right">
            <b-breadcrumb :items="items" class="m-0"></b-breadcrumb>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="float-right d-none d-md-block">
          <b-button v-if="$store.getters.checkPermission('EXPORT_DATA_CHECKOUT_PRODUCT')" right variant="outline-primary" class="mr-2" @click="generateReport">Export Report</b-button>
          <b-button v-if="$store.getters.checkPermission('ADD_CHECKOUT_PRODUCT')" right variant="primary" :to="'/buy-product/add'">Add Checkout</b-button>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <p class="card-title-desc"></p>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-2">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                     <span class="mr-2"> Show </span>
                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select> <span class="ml-2">entries</span>
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-10">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search user..."
                      class="form-control ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                ref="table"
                :items="fetchData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :empty-text="'There are no records to show'"
                :empty-filtered-text="'There are no records matching your request'"
              >
                <!-- A virtual column -->
                <template v-slot:cell(No)="data">
                  {{ (currentPage - 1) * perPage + (data.index + 1) }}
                </template> 
                <!-- Full Name -->
                <template v-slot:cell(user)="data">
                  <b>{{ data.item.user }}</b>
                </template>
                <!-- Total -->
                <template v-slot:cell(total)="data">
                  <b>Rp. {{ data.item.total | convertToRupiah }}</b>
                </template>
                <!-- Aciton -->
                <template v-slot:cell(action)="data">                  
                  <b-button variant="outline-warning"  v-b-tooltip.hover title="View Detail" size="sm" @click.prevent="showCheckoutId = data.item.checkoutId">Detail</b-button> &nbsp;
                  <b-button v-if="$store.getters.checkPermission('PRINT_RECEIPT_CHECKOUT_PRODUCT')" :href="data.item.print" target="_blank" variant="outline-primary"  v-b-tooltip.hover title="Print Receipt Checkout" size="sm">Print</b-button> &nbsp;
                  <b-button :variant="data.item.isVoid ? 'danger' : 'outline-danger'"  v-b-tooltip.hover :title="data.item.isVoid ? 'Unvoid' : 'Void'" size="sm" @click.prevent="voidData(data.item.checkoutId)">{{ data.item.isVoid ? 'Voided' : 'Set To Void' }}</b-button> 
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <detail-checkout :id="showCheckoutId" @close="showCheckoutId = null"></detail-checkout>
    
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import DetailCheckout from './detail';
import Swal from "sweetalert2";

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Sale Product",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, DetailCheckout },
  data() {
    return {
      title: "Sale Product",
      items: [
        {
          text: "Home",
          href: "/home"
        },
        {
          text: "List Checkout Product",
          active: true
        },
      ],
      showCheckoutId: null,
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      sortBy: "createdAt",
      sortDesc: false,
      fields: [
        'No',
        { key: "booking_code", label: "Order", sortable: true }, 
        { key: "user", label: "User", sortable: true },
        { key: "total", label: "Total", sortable: true }, 
        { key: "payment_method", label: "Payment Method", sortable: true },
        { key: "createdAt", label: "Checkout Date", sortable: true },
        'Action'
      ],
      date: [],
    };
  },
  mounted() {
    if (this.$route.query.show !== undefined) {
      this.showCheckoutId = this.$route.query.show
    }
  },
  methods: {
    async fetchData(ctx) { 
      console.log(ctx)
      const promise = this.$axios.get(`checkout-product`, {
        params: {
        page: ctx.currentPage,
        limit: ctx.perPage,
        user: ctx.filter,
        sortBy: ctx.sortBy,
        sortType: (ctx.sortDesc)? 1 : -1
        }
      })

      // Must return a promise that resolves to an array of items
      return promise.then(response => {
        // Pluck the array of items off our axios response
        console.log(response)
       
        const items = response.data.result.checkouts
        this.totalRows = response.data.result.total
        // Must return an array of items or an empty array if an error occurred
        return items || []
      })
    },
    generateReport() {
      window.location.href = `${process.env.VUE_APP_BASE_URL_API}/checkout-product/export/product-sales-report`
    },
    async voidData(checkoutId) {
      await this.$axios
        .put(`/checkout-product/void/${checkoutId}`)
        .then(() => Swal.fire("Success", "Checked Successfully Updated", "success"))
        .catch((e) => console.log(e))
        .finally(() => this.$refs.table.refresh());
    }
  }
};
</script>