<template>
  <div>
    <b-modal
      hide-footer
      size="xl"
      id="modal-detail"
      centered
      title="Detail Checkout"
      title-class="font-18" 
      @close="$emit('close')"
      no-close-on-backdrop
      >
      <div class="d-inline-block w-100 pad-10" v-if="checkout">
        <div class="row">
          <div class="col-md-12 shadow-card for-head">
            <div class="row">
              <div class="col-md-12 mb-10 ">
                <span class="font-16 d-block text-white font-weight-bold pb-6 border-bottom">Detail User</span>
              </div>
              <div class="col-md-3">
                <span class="d-block font-weight-bold font-12 text-white">
                  Firstname 
                </span>
                <span class="text-white font-weight-bold">{{ checkout.user.firstname | capitalize }}</span>
              </div> 
              <div class="col-md-3">
                <span class="d-block font-weight-bold font-12 text-white">
                  Lastname 
                </span>
                <span class="text-white font-weight-bold">{{ checkout.user.lastname | capitalize }}</span>
              </div> 
              <div class="col-md-3">
                <span class="d-block font-weight-bold font-12 text-white">
                  Email 
                </span>
                <span class="text-white font-weight-bold">{{ checkout.user.email }}</span>
              </div>
              <div class="col-md-3">
                <span class="d-block font-weight-bold font-12 text-white">
                  Phone 
                </span>
                <span class="text-white font-weight-bold">{{ checkout.user.phone }}</span>
              </div>
            </div> 
          </div>

          <div class="col-md-12 shadow-card for-head" v-if="checkout.location">
            <div class="row">
              <div class="col-md-12 mb-10 ">
                <span class="font-16 d-block text-white font-weight-bold pb-6 border-bottom">Detail Location</span>
              </div>
              <div class="col-md-3">
                <span class="d-block font-weight-bold font-12 text-white">
                  Name 
                </span>
                <span class="text-white font-weight-bold">{{ checkout.location.name | capitalize }}</span>
              </div> 
              <div class="col-md-3">
                <span class="d-block font-weight-bold font-12 text-white">
                  Phone 
                </span>
                <span class="text-white font-weight-bold">{{ checkout.location.phone }}</span>
              </div>
              <div class="col-md-6">
                <span class="d-block font-weight-bold font-12 text-white">
                  Address 
                </span>
                <span class="text-white font-weight-bold">{{ checkout.location.address }}</span>
              </div>
            </div> 
          </div>

          <div class="col-md-12 shadow-card">
            <div class="row">
              <div class="col-md-12 mb-10 ">
                <span class="font-16 d-block text-primary font-weight-bold pb-6 border-bottom">Products </span>
              </div>
              <template v-for="(data, index) in checkout.products">
                <div :key="index">
                  <div class="col-md-12 mb-2">
                    <span class="d-block font-weight-bold font-12 text-primary">
                      Name  
                    </span>
                    <span class="text-secondary font-weight-bold">{{ data.name }}</span>
                  </div> 
                  <div class="col-md-12 mb-2">
                    <span class="d-block font-weight-bold font-12 text-primary">
                      Price
                    </span>
                    <span class="text-secondary font-weight-bold">Rp. {{ data.price | convertToRupiah }}</span>
                  </div> 
                  <div class="col-md-12 mb-2">
                    <span class="d-block font-weight-bold font-12 text-primary">
                      Quantity 
                    </span>
                    <span class="text-secondary font-weight-bold">{{ data.quantity }}</span>
                  </div> 
                  <div class="col-md-12 mb-2">
                    <span class="d-block font-weight-bold font-12 text-primary">
                      SubTotal 
                    </span>
                    <span class="text-secondary font-weight-bold">Rp. {{ (data.price * data.quantity) | convertToRupiah }}</span>
                  </div> 
                </div>
              </template>
            </div>
          </div>

          <div class="col-md-12 shadow-card for-head">
            <div class="row">
              <div class="col-md-12 mb-10">
                <span class="font-16 d-block text-white font-weight-bold pb-6 border-bottom">Summary Payment</span>
              </div>
              <div class="col-md-2 mb-10">
                <span class="d-block text-white opacity-5 font-weight-bold font-12">
                  Payment Method 
                </span>
                <span class="text-white font-weight-bold font-18">{{ checkout.paymentMethod }}</span>
              </div>
              <div class="col-md-2 mb-10">
                <span class="d-block text-white opacity-5 font-weight-bold font-12">
                  Coupon 
                </span>
                <span class="text-white font-weight-bold font-18">{{ checkout.couponCode || '-' }}</span>
              </div>
              <div class="col-md-2 mb-10">
                <span class="d-block text-white opacity-5 font-weight-bold font-12">
                  Subtotal 
                </span>
                <span class="text-white font-weight-bold font-18" v-if="checkout.subTotal">Rp. {{ checkout.subTotal | convertToRupiah }}</span>
                <span class="text-white font-weight-bold font-18" v-else>Rp. {{ checkout.total | convertToRupiah }}</span>
              </div>
              <div class="col-md-2 mb-10">
                <span class="d-block text-white opacity-5 font-weight-bold font-12">
                  Discount 
                </span>
                <span class="text-white font-weight-bold font-18">Rp. {{ checkout.discount | convertToRupiah }}</span>
              </div>
              <div class="col-md-4 mb-10">
                <div class="float-right">
                  <span class="d-block text-white opacity-5 font-weight-bold font-12">
                    Total 
                  </span>
                  <span class="text-white font-weight-bold font-18">Rp. {{ checkout.total | convertToRupiah }}</span>
                </div>
              </div>
            </div> 
          </div>
        </div> 
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'detail-checkout',
  props: {
    id: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      checkout: null
    }
  },
  watch: {
    id(newVal) {
      if (newVal !== null) {
        this.getDetail()
      } 
    }
  },
  methods: {
    getDetail() {
      this.$bvModal.show('modal-detail')
      this.$axios.get(`checkout-product/${this.id}`)
        .then( response => {
          this.checkout = response.data.result.checkout
        })
    }
  }
}
</script>